import { createRef, useState, useEffect, createContext } from 'react';
import { getAutoRunSetting, setAutoRunSetting } from './helpers/AutoRunHelper';
import { Box, Button } from "@mui/material";
import { Helmet } from 'react-helmet-async';
import PageLoader from './components/PageLoader';
import { BrowserRouter } from 'react-router-dom';
import { useAuthState } from './helpers/Context';
import { ThemeProvider } from '@mui/material/styles';
import { useBranding } from './hooks/useBranding';
import CacheBuster from 'react-cache-buster';
import { SnackbarProvider } from 'notistack';
import packageInfo from '../package.json';
import { useCSP } from './hooks/useCSP';
import Favicon from 'react-favicon';
import LogRocket from 'logrocket';
import Routes from './router';


export const HelpContext = createContext(null);
export const LoaderContext = createContext(null);

const notistackRef = createRef();
const onClickDismiss = key => () => {
  notistackRef.current.closeSnackbar(key);
};

const App = () => {
  const state = useAuthState();
  const csp = useCSP(state.tenant);
  const isProduction = process.env.NODE_ENV === 'production';
  const [autoRunHelp, setAutoRunHelp] = useState(getAutoRunSetting());
  const { brandName, favicon, thumbnail, spinner, theme } = useBranding(state.tenant);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const [contextChange, setContextChange] = useState(false);
  const [contentSecurityPolicy] = useState(csp);

  useEffect(() => {
    if (isProduction) {
      if (state?.roles?.authenticated && state.profile && state.tenant) {
        LogRocket.identify(state.profile.username, {
          name: state.profile.profile?.full_name,
          tenantId: state.tenant.tenant_id || state.tenantId,
          subscriptionType: state.tenant.subscription?.type,
          environment: process.env.NODE_ENV,
          url: window.location.href
        });
      }
    }
  }, [state?.roles, state?.profile, state?.tenant]);

  useEffect(() => {
    setAutoRunSetting(autoRunHelp);
  }, [autoRunHelp]);

  const handleAutoRunHelp = () => {
    setAutoRunHelp(prevState => !prevState);
  };

  const handleOpenUserMenu = (value) => {
    setOpenUserMenu(value === 'true' || value === true);
  };

  const handleContextChange = () => {
    setContextChange(!contextChange);
  };

  return (
    <CacheBuster
      currentVersion={packageInfo.version}
      isEnabled={isProduction}
      isVerboseMode={false}
      loadingComponent={<PageLoader />}
    >
      <Box className="app">
        {contentSecurityPolicy &&
          <Helmet>
            <meta
              httpEquiv="Content-Security-Policy"
              content={contentSecurityPolicy}
            ></meta>
          </Helmet>
        }
        {favicon &&
          <Favicon url={favicon} />
        }
        {theme &&
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={3}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              ref={notistackRef}
              action={(key) => (
                <Button onClick={onClickDismiss(key)}>
                  Dismiss
                </Button>
              )}>
              <LoaderContext.Provider value={{ spinner }}>
                <HelpContext.Provider value={{
                  handleAutoRunHelp, handleOpenUserMenu, handleContextChange,
                  autoRunHelp, thumbnail, brandName, openUserMenu,
                  contextChange
                }}>
                  <div className="App">
                    <BrowserRouter>
                      <Routes />
                    </BrowserRouter>
                  </div>
                </HelpContext.Provider>
              </LoaderContext.Provider>
            </SnackbarProvider>
          </ThemeProvider>
        }
      </Box>
    </CacheBuster>
  );
};

export default App;